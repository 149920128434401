// Benzine->Gas savings calculator.
//

$(document).ready(function() {
  "use strict"

  const CALC_SELECTOR = '#savings-calc'
  if (!document.querySelector(CALC_SELECTOR)) {
    return
  }

  const SLIDER_CONSUMPTION_ID = 'savings-consumption-slider'
  const SLIDER_CONSUMPTION_MIN = 5
  const SLIDER_CONSUMPTION_MAX = 40
  const SLIDER_CONSUMPTION_START = 10
  const SLIDER_CONSUMPTION_STEP = 1

  const SLIDER_KILOMETRAGE_ID = 'savings-kilometrage-slider'
  const SLIDER_KILOMETRAGE_MIN = 500
  const SLIDER_KILOMETRAGE_MAX = 30000
  const SLIDER_KILOMETRAGE_START = 5000
  const SLIDER_KILOMETRAGE_STEP = 500

  const GAS_SWITCH_ID = 'savings-gas-switch'

  const RESULT_YEAR  = 'savings-yearly'
  const RESULT_MONTH = 'savings-monthly'


  // Create sliders
  //

  const tooltipFormat = {
    to:   function (value) {
      return Math.ceil(value)
    },
    from: function (value) { return value }
  }

  const sliderConsumption = document.getElementById(SLIDER_CONSUMPTION_ID)
  noUiSlider.create(sliderConsumption, {
    range: {'min': SLIDER_CONSUMPTION_MIN, 'max': SLIDER_CONSUMPTION_MAX},
    start: [SLIDER_CONSUMPTION_START],
    step: SLIDER_CONSUMPTION_STEP,
    connect: 'lower',
    tooltips: [tooltipFormat],
    orientation: 'horizontal',
  })

  const sliderKilometrage = document.getElementById(SLIDER_KILOMETRAGE_ID)
  noUiSlider.create(sliderKilometrage, {
    range: {'min': SLIDER_KILOMETRAGE_MIN, 'max': SLIDER_KILOMETRAGE_MAX},
    start: [SLIDER_KILOMETRAGE_START],
    step: SLIDER_KILOMETRAGE_STEP,
    connect: 'lower',
    tooltips: [tooltipFormat],
    orientation: 'horizontal',
  })


  // Output the calculation results
  //

  const $gasSwitch   = $(document.getElementById(GAS_SWITCH_ID))
  const $outputYear  = $(document.getElementById(RESULT_YEAR))
  const $outputMonth = $(document.getElementById(RESULT_MONTH))

  function updateResult() {
    const monthlySavings = calcMonthlySavings()
    $outputMonth.text(formatNumber(monthlySavings))
    $outputYear.text(formatNumber(monthlySavings * 12))
  }

  function calcMonthlySavings() {
    const consumption = sliderConsumption.noUiSlider.get()
    const kilometrage = sliderKilometrage.noUiSlider.get()

    const propaneCost = window.backendData.propaneCost
    const methaneCost = window.backendData.methaneCost
    const benzineCost = window.backendData.benzineCost
    const gasCost = $gasSwitch.prop('checked') ? propaneCost : methaneCost

    const savings = (benzineCost - gasCost) * (kilometrage / 100 * consumption)
    return Math.ceil(savings)
  }

  function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
  }


  // Initialize
  //

  // The 'update' event is fired when the page is loaded (for each slider), no
  // need to explicitly call updateResult().
  sliderConsumption.noUiSlider.on('update', updateResult)
  sliderKilometrage.noUiSlider.on('update', updateResult)
  $gasSwitch.click(updateResult)
})
