import './init_materialize'
import './init_swiper'

import './utils'
import './questionnaire'
import './callback'
import './phone_fab'
import './savings_calc'


$(document).ready(function() {
  window.alertModal = M.Modal.getInstance(document.getElementById('alert-modal'))
})