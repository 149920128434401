import 'cleave.js/dist/cleave-esm'
import 'cleave.js/dist/addons/cleave-phone.ru'


;$(document).ready(function() {
  "use strict"

  const QNR_SELECTOR = '#heaters-qnr-modal'
  if (!document.querySelector(QNR_SELECTOR)) {
    return
  }

  //
  // Конфигурация
  //

  const QNR_ID = 'heaters-qnr-modal'

  const BLOCK = '.qnr-block'

  const ACTIVE_BLOCK_CLASS = 'active'

  const BLOCK_IDS = [
    "heaters-qnr-question-car_type",
    "heaters-qnr-question-heater_type",
    "heaters-qnr-question-extras",
    "heaters-qnr-contacts",
  ]  // Порядок важен!

  const PREV_BUTTON = "#heaters-qnr-backward"

  const PROGRESS_BAR = "#heaters-qnr-progress-bar"
  const PROGRESS_PCT = "#heaters-qnr-progress-pct"

  const CHOICE_REQUIRED = '[data-choice-required]'

  const SUCCESS_ALERT_TITLE = "Спасибо!"
  const SUCCESS_ALERT_BODY = (
    'Мы перезвоним на номер <span class="phone-number">{phone}</span> в ближайшее время.'
  )
  const FAILURE_ALERT_TITLE = "Ошибка!"
  const FAILURE_ALERT_BODY = (
    "При отправке опроса произошла неизвестная ошибка."
  )


  //
  // Глобальные переменные
  //

  const qnr = document.getElementById(QNR_ID)
  const $qnr = $(qnr)
  const modalInstance = M.Modal.getInstance(qnr)
  const $form = $qnr.find('form')
  const $submitButton = $form.find('[type="submit"]')
  const $phoneInput = $form.find('[name="phone"]')
  const phoneInput = $phoneInput.get(0)
  const $prevButton  = $(PREV_BUTTON)
  const $requiredChoices = $(CHOICE_REQUIRED).find('[name]')

  let _cursor = 0


  //
  // Публичные глобальные функции
  //


  function startQnr() {
    /* Начать квиз и привести его в изначальное состояние */
    modalInstance.open()
    resetQnr()
  }


  function closeQnr() {
    /* Остановить квиз и привести его в изначальное состояние */
    modalInstance.close()
    resetQnr()
  }


  function resetQnr() {
    /* Привести квиз в изначальное состояние */
    _cursor = 0

    // Прячем все блоки, показываем первый
    $(BLOCK).removeClass(ACTIVE_BLOCK_CLASS)
    const firstBlock = document.getElementById(BLOCK_IDS[0])
    $(firstBlock).addClass(ACTIVE_BLOCK_CLASS)

    // "Забываем" каждый выбор пользователя
    $requiredChoices.prop("checked", false)

    // Сбрасываем состояние кнопок
    _disablePrevButton()

    // Обнуляем прогресс пользователя
    _updateProgress()
  }


  function moveQnrForward(event) {
    /* Перейти к следующему блоку квиза */

    // Не делать ничего, если это последний блок
    if (! _hasNextBlock()) { return }

    const currentBlock = document.getElementById(BLOCK_IDS[_cursor])
    const $currentBlock = $(currentBlock)

    // Прячем текущий блок
    $currentBlock.removeClass(ACTIVE_BLOCK_CLASS)

    _cursor += 1

    // Показываем следующий блок
    const nextBlock = document.getElementById(BLOCK_IDS[_cursor])
    $(nextBlock).addClass(ACTIVE_BLOCK_CLASS)

    // Обновляем индикатор прогресса пользователя
    _updateProgress()

    // Обновляем состояние кнопки "назад"
    if (_hasPrevBlock()) {
      _enablePrevButton()
    }
  }


  function moveQnrBackward(event) {
    /* Перейти к предыдущему блоку квиза */

    // Не делать ничего, если это первый блок
    if (! _hasPrevBlock()) { return }

    // Прячем текущий блок
    const currentBlock = document.getElementById(BLOCK_IDS[_cursor])
    $(currentBlock).removeClass(ACTIVE_BLOCK_CLASS)

    _cursor -= 1

    // Показываем предыдущий блок
    const nextBlock = document.getElementById(BLOCK_IDS[_cursor])
    $(nextBlock).addClass(ACTIVE_BLOCK_CLASS)

    // Обновляем индикатор прогресса пользователя
    _updateProgress()

    // Обновляем состояние кнопки "назад"
    if (! _hasPrevBlock()) {
      _disablePrevButton()
    }
  }


  function isQnrCompleted() {
    return getQnrProgress() == 1
  }


  function getQnrProgress() {
    /* Вернуть текущий прогресс пользователя (number) */

    return _cursor / (BLOCK_IDS.length - 1)
    // Мы вычитаем 1, чтобы не учитывать последний блок, в котором
    // запрашиваются контактные данные посетителя. Без этого действия
    // посетитель никогда бы не увидел 100%.
  }


  function sendQnr(event) {
    /* Отослать законченный квиз на сервер */

    if (event) {
      event.preventDefault()
    }

    if (!isQnrCompleted) {
      return
    }

    const postUrl = $form.attr("action")
    const postData = $form.serialize()

    const sentPhoneNumber = $phoneInput.val()  // Для оповещений
    $.post(postUrl, postData)
      .done(function(data) {
        var msg_body = SUCCESS_ALERT_BODY.replace('{phone}', sentPhoneNumber)
        utils.alertSuccess(SUCCESS_ALERT_TITLE, msg_body)
      })
      .fail(function(jqXHR, textStatus, errorThrown) {
        utils.alertFailure(FAILURE_ALERT_TITLE, FAILURE_ALERT_BODY)
      })
      .always(function() {
        utils.restoreDefaultButton($submitButton)
        closeQnr()
      })
    utils.makeWaitButton($submitButton)
  }


  //
  // Приватные глобальные функции
  //

  function _updateProgress() {
    const pct = Number.parseFloat(getQnrProgress() * 100).toFixed(0)
    const $progressBar = $(PROGRESS_BAR)
    const $progressPct = $(PROGRESS_PCT)

    $progressPct.text(pct)
    $progressBar.css("width", pct + '%')
  }

  function _hasPrevBlock() {
    return _cursor > 0
  }

  function _hasNextBlock() {
    return _cursor < BLOCK_IDS.length - 1
  }

  function _disableButton(button) {
    $(button).prop("disabled", true)
  }

  function _enableButton(button) {
    $(button).prop("disabled", false)
  }

  function _enablePrevButton() { _enableButton($prevButton) }
  function _disablePrevButton() { _disableButton($prevButton) }


  //
  // Инициализация
  //

  $prevButton.click(moveQnrBackward)
  $requiredChoices.click(moveQnrForward)
  $form.submit(sendQnr)

  new Cleave(phoneInput, {
    phone: true,
    phoneRegionCode: 'ru',
  })


  //
  // Экспорт в глобальное пространство имен
  //

  window.heatersQnr = {
    start:        startQnr,
    close:        closeQnr,
    send:         sendQnr,
    reset:        resetQnr,
    getProgress:  getQnrProgress,
    isCompleted:  isQnrCompleted,
    backward:     moveQnrBackward,
    forward:      moveQnrForward,
  }
});