// "Phone" floating action button.
//

import "./materialize"
import "./utils"

;$(document).ready(function() {

  const MODAL_ID = 'fab-callback-modal'
  const $fab = $('#phone-fab')

  function openCallbackModal() {
    const modalElem = document.getElementById(MODAL_ID)
    const modalInstance = M.Modal.getInstance(modalElem)
    modalInstance.open()
  }

  $fab.click(function(event) {
    if (!utils.isPhoneDevice()) {
      event.preventDefault()
      openCallbackModal()
    }
  })

})
