// Проверка формы заказа обратного звонка.
//

import 'cleave.js/dist/cleave-esm'
import 'cleave.js/dist/addons/cleave-phone.ru'


;$(document).ready(function() {
  "use strict";

  var DEFAULT_SUCCESS_TITLE = "Обратный звонок заказан"
  var DEFAULT_SUCCESS_BODY = (
    'Мы перезвоним на номер <span class="phone-number">{phone}</span> в ближайшее время.'
  )
  var DEFAULT_FAILURE_TITLE = "Ошибка!"
  var DEFAULT_FAILURE_BODY = (
    "При отправке заявки произошла неизвестная ошибка. Попробуйте " +
    "позже или свяжитесь с нами используя контакты, указанные в верхней части сайта."
  )

  var $callbackForms = $('.callback-form')

  $callbackForms.each(function(index) {
    var $form = $(this),
        $phoneInput   = $form.find('[name="phone"]'),
        $submitButton = $form.find('[type="submit"]'),
        $modal        = $form.closest('.modal'),
        $closeButton = $modal.find('.modal-close'),
        failureTitle = $modal.find('.failure-title').html() || DEFAULT_FAILURE_TITLE,
        failureBody  = $modal.find('.failure-body').html()  || DEFAULT_FAILURE_BODY,
        successTitle = $modal.find('.success-title').html() || DEFAULT_SUCCESS_TITLE,
        successBody  = $modal.find('.success-body').html()  || DEFAULT_SUCCESS_BODY

    var form = $form[0],
        phoneInput = $phoneInput.get(0),
        cleave = new Cleave(phoneInput, {
          phone: true,
          phoneRegionCode: 'ru',
        })

    $form.submit(function(event) {
      event.preventDefault()

      if (form.checkValidity() === false) {
        return
      }

      var postUrl = $form.attr('action'),
          postData = $form.serialize(),
          sentPhoneNumber = $phoneInput.val()

      $.post(postUrl, postData)
        .done(function(data) {
          successBody = successBody.replace('{phone}', sentPhoneNumber)
          utils.alertSuccess(successTitle, successBody)
        })
        .fail(function(jqXHR, textStatus, errorThrown) {
          utils.alertFailure(failureTitle, failureBody)
        })
        .always(function() {
          utils.restoreDefaultButton($submitButton)
          $closeButton.click()
        })
      utils.makeWaitButton($submitButton)
    })
  })

})
