import Swiper from 'swiper'


const defaultNavigation = {
  nextEl: '.swiper-button-next',
  prevEl: '.swiper-button-prev',
}

const numberedPagination = {
  el: '.swiper-pagination',
  clickable: true,
  renderBullet: function (index, className) {
    return '<span class="' + className + '">' + (index + 1) + '</span>';
  },
}


// "Heater benefits" slider
//

new Swiper ('#heaters-benefits-slider', {
  initialSlide: window.initialHeaterBenefitSlide,
  autoHeight: true,
  loop: false,
  navigation: defaultNavigation,
  pagination: numberedPagination,
})


// "Gas Equipment Myths" slider
//
new Swiper ('#gas-myths-slider', {
  autoHeight: true,
  loop: false,
  navigation: defaultNavigation,
  pagination: numberedPagination,
})


// "Our Works" slider
//

new Swiper('#our-works-slider', {
  spaceBetween: 5,
  slidesPerView: 'auto',
  navigation: defaultNavigation,

  pagination: {
    el: '.swiper-pagination',
    type: 'fraction',
    clickable: false,
  },
});
