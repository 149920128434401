import "./materialize"


$(document).ready(function() {
  $(".sidenav").sidenav()

  $(".dropdown-trigger").dropdown({
    constrainWidth: false,
    coverTrigger: false,
  })

  $(".scrollspy").scrollSpy()
  $(".modal").modal()
  $("input[data-length]").characterCounter()
  $(".fixed-action-btn").floatingActionButton()
})
